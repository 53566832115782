/* ideology */
.boundary {
  width: 60%;
  padding-top: 60%;
  border-radius: 100%;
  border: 1px solid #e3e3e3;
  position: relative;
}
.innerCenter {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 65%;
  padding-top: 65%;
  border-radius: 100%;
  border: 6px solid #e3e3e3;
  background-color: #e3e3e3;
  box-shadow: -5px 7px 20px rgba(0, 0, 0, 0.25);
}
.innercontent {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.satellite:nth-child(2) {
  right: -30%;
  top: 5%;
}
.satellite:nth-child(3) {
  right: -45%;
  top: calc(50% - 6rem);
}
.satellite:nth-child(4) {
  right: -30%;
  bottom: 5%;
}
.w-330 {
  width: 330px;
}
.visioncircle {
  width: 25%;
  padding-top: 25%;
  background-color: #e5e5e5;
}
.apth {
  width: 115%;
}
.apth path {
  fill: #e5e5e5;
  transition: fill 0.3s ease;
}
.visioncircle:hover .apth path {
  fill: #da3924;
  transition: fill 0.8s ease;
}
.apthup {
  top: -8%;
  left: -5%;
}
.apthdown {
  bottom: -8%;
  left: -8%;
}
.itr_confade1 {
  width: 8.7%;
  left: 26.9%;
  top: 49.5%;
  height: 0.3px;
}

.itr_confade2 {
  width: 8.45%;
  left: 64.9%;
  top: 51.1%;
  height: 0.3px;
}

/* phil */
.coaster {
  background: url(/src/assets/images/intro/coaster.png) no-repeat center center /
    contain;
}
.coaster .between {
  width: 25%;
}
.centertitle {
  text-indent: 10px;
}
.oneoffive {
  display: inline-block;
  width: 20%;
  padding-top: 20%;
}
.oneoffive .bor {
  left: -16px;
}
.oneoffive .topC {
  width: calc(100% + 32px);
  height: calc(50% + 16px);
  top: -16px;
  border: 16px solid #da3924;
  border-bottom: 0;
}

.oneoffive .bottomC {
  width: calc(100% + 32px);
  height: calc(50% + 16px);
  bottom: -16px;
  border: 16px solid #da3924;
  border-top: 0;
}

.oneoffive:nth-child(1) .bor {
  border-color: #da3924;
}
.oneoffive:nth-child(2) .bor {
  border-color: #b23e2d;
}
.oneoffive:nth-child(3) .bor {
  border-color: #605169;
}
.oneoffive:nth-child(4) .bor {
  border-color: #4a314d;
}
.oneoffive:nth-child(5) .bor {
  border-color: #302f49;
}

.oneoffive:nth-child(1) .topC {
  z-index: 5;
}
.oneoffive:nth-child(2) .topC {
  z-index: 4;
}
.oneoffive:nth-child(3) .topC {
  z-index: 3;
}
.oneoffive:nth-child(4) .topC {
  z-index: 2;
}
.oneoffive:nth-child(5) .topC {
  z-index: 1;
}
.oneoffive:nth-child(5) .bottomC {
  z-index: 5;
}
.oneoffive:nth-child(4) .bottomC {
  z-index: 4;
}
.oneoffive:nth-child(3) .bottomC {
  z-index: 3;
}
.oneoffive:nth-child(2) .bottomC {
  z-index: 2;
}
.oneoffive:nth-child(1) .bottomC {
  z-index: 1;
}
.line {
  height: 1px;
  margin: 8px 0;
  background-color: #e5e5e5;
}
/* history */
.tree {
  width: 64rem;
}
.tree li .pillar {
  width: 1px;
  height: 100%;
  margin: 0 auto;
  background-color: #a7a8a9;
}
.his-point {
  background-color: #a7a8a9;
  left: 50%;
  transform: translate(-50%);
}
.now-point {
  background-color: var(--logo);
  left: calc(50% - 1rem);
}
.tree .branch {
  width: 50%;
  border-top: 1px solid #a7a8a9;
}
.tree .branch .his-textbox {
  top: -4.5rem;
}

.tree li:nth-child(odd) .branch {
  float: right;
  text-align: left;
}
.tree li:nth-child(odd) .branch .his-textbox {
  left: 7rem;
}
.tree li:nth-child(even) .branch {
  float: left;
  text-align: right;
}
.tree li:nth-child(even) .branch .his-textbox {
  right: 7rem;
}

/* certification */
.license {
  width: 30%;
}
.license .paper {
  position: relative;
  padding-top: calc(100% * 481 / 333);
}

/* smaller than 1024 */
@media screen and (max-width: 1024px) {
  .boundary {
    border: 0;
  }
  .innerCenter {
    left: 0;
    top: 0;
    transform: translate(0, 0);
    width: 100%;
    padding-top: 100%;
    border-radius: 100%;
    border: 1px solid #e3e3e3;
    background-color: transparent;
    box-shadow: none;
  }
  .innercontent {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 75%;
    height: 75%;
    border-radius: 100%;
    background-color: #e3e3e3;
    box-shadow: -5px 7px 20px rgba(0, 0, 0, 0.25);
  }
  .satellite {
    margin: 30px auto 0;
  }
  .visioncircle {
    width: 350px;
    padding-top: 350px;
    background-color: #e5e5e5;
    margin: 30px auto;
  }
  .apthup {
    top: 22%;
    left: 22%;
  }
  .apthdown {
    bottom: 21%;
    left: -37%;
  }
  .itr_confade1 {
    transform: scale(1.15);
    width: 0.5px;
    height: 54px;
    margin: 0 auto;
  }

  .itr_confade2 {
    transform: scale(1.15);
    padding-top: 5px;
    width: 0.5px;
    height: 54px;
    margin: 0 auto;
  }
  .coaster {
    background: none;
  }
  .coaster-mobile {
    width: 330px;
    height: 330px;
    margin: 0 auto;
    background: url(../../assets/images/intro/coaster-mobile.png) no-repeat
      center center / contain;
  }
  .coaster .between {
    width: 400px;
    margin: 50px auto 0;
  }
  .oneoffive {
    display: block;
    width: 230px;
    padding-top: 230px;
    margin: 0 auto;
  }
  .tree {
    width: 100%;
  }
}
/* smaller than 	640px */
@media screen and (max-width: 640px) {
  .boundary {
    width: 90%;
    padding-top: 90%;
  }
  .innercontent {
    width: 85%;
    height: 85%;
    box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.25);
  }
  .visioncircle {
    width: 230px;
    padding-top: 230px;
    background-color: #e5e5e5;
    margin: 30px auto;
  }
  .itr_confade1 {
    transform: scale(1.8);
    height: 32px;
  }

  .itr_confade2 {
    padding-top: 2.8px;
    height: 32px;
    transform: scale(1.8);
  }
  .coaster-mobile {
    width: 250px;
    height: 250px;
    margin: 0 auto;
    background: url(../../assets/images/intro/coaster-mobile.png) no-repeat
      center center / contain;
  }
  .coaster .between {
    width: 250px;
    margin: 50px auto 0;
  }
  .tree .branch .his-textbox {
    top: -2.6em;
  }
  .tree li:nth-child(odd) .branch .his-textbox {
    left: 3rem;
    width: calc(100% - 3rem);
  }
  .tree li:nth-child(even) .branch .his-textbox {
    right: 3rem;
    width: calc(100% - 3rem);
  }
  .license {
    width: 80%;
    margin: 0 auto;
  }
  .license .paper {
    position: relative;
    padding-top: calc(100% * 481 / 333);
  }
}
