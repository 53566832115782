.border-5 {
  border-width: 5px;
}
/* main */
.mainheight {
  height: 50vh;
}
.swiper-container {
  overflow: visible !important;
}
.text-border-white {
  color: var(--main2);
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff,
    1px 1px 0 #fff;
}

.rotatedText {
  position: absolute;
  writing-mode: tb-rl;
  letter-spacing: 0;
  top: 0;
  left: 0;
  text-transform: uppercase;
  transform: scale(-1);
  font-size: 2rem;
  font-weight: 500;
}
.facilS .swiper-pagination-bullet {
  display: inline-block;
  width: 50px;
  height: 6px;
  margin: 0 5px;
  background-color: rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.swiper-pagination {
  left: 50%;
  transform: translateX(-50%);
}
.facilS .swiper-pagination-bullet-active {
  background-color: #fff;
}

.f-button-l {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  z-index: 1;
}
.f-button-r {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  z-index: 1;
  height: 37.6rem;
  background-color: rgba(19, 21, 41, 1);
}

.innerSbox {
  height: 60vh;
  max-height: 480px;
  box-sizing: border-box;
  padding: 0 8rem;
}

.textbox {
  max-width: 50%;
}
/* slide next setting */
@media (min-width: 1025px) {
  .swiper-slide-next .innerSbox {
    padding: 0;
  }
  .swiper-slide-next .textbox {
    position: absolute;
    left: 5rem;
    top: 0;
    z-index: 2;
    width: 13rem;
    height: 51rem;
    background-color: rgba(19, 21, 41, 1);
    text-align: center;
    justify-content: center;
    padding: 0;
  }
  .swiper-slide-next .textbox .number {
    padding: 0;
  }
  .swiper-slide-next .textbox .title {
    font-size: 2rem;
    position: absolute;
    bottom: 1rem;
    padding: 10px;
  }
  .swiper-slide-next .textbox .desc,
  .swiper-slide-next .textbox .moveto {
    display: none;
  }
  .swiper-slide-prev .f-photo,
  .swiper-slide-next .f-photo {
    opacity: 0.5;
  }
  .swiper-slide-prev .bottomText,
  .swiper-slide-next .bottomText {
    opacity: 0;
  }
}

@media screen and (max-width: 1536px) {
  .rotatedText {
    display: none;
  }
  .f-button-l {
    left: 0;
  }
  .f-button-r {
    right: 0rem;
    background-color: rgba(19, 21, 41, 1);
  }
  .innerSbox {
    padding: 0 15rem;
  }
}

/* product */
.prd-image,
.prd-video {
  transform: scale(1);
  transition: transform 0.5s;
}
.swiper-slide:not(.swiper-slide-active) .prd-image,
.swiper-slide:not(.swiper-slide-active) .prd-video {
  transform: scale(0.9);
  transition: transform 0.5s;
  opacity: 0.5;
}
.prodS .swiper-pagination-bullet {
  display: inline-block;
  width: 50px;
  height: 6px;
  margin: 0 5px;
  background-color: rgba(255, 255, 255, 0.3);
  cursor: pointer;
}
.prodS .swiper-pagination-bullet-active {
  background-color: #fff;
}

/* strength */
.st-inbox {
  width: 25%;
  padding-top: 25%;
  position: relative;
}
.st-innerbox {
  width: 90%;
  position: absolute;
  left: 5%;
  top: 5%;
  height: 90%;
  background: no-repeat center center;
  background-size: 190%;
  transition: background-size 0.5s;
}
.st-innerbox:hover {
  background-size: 210%;
  transition: background-size 0.5s;
}
.st-innerbox:not(.st-inbox:nth-child(1) .st-innerbox)::before {
  content: "";
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.4);
}
.st-inbox:nth-child(2) .st-innerbox {
  background-image: url(/src/assets/images/home/strength1.png);
}
.st-inbox:nth-child(3) .st-innerbox {
  background-image: url(/src/assets/images/home/strength2.png);
}
.st-inbox:nth-child(4) .st-innerbox {
  background-image: url(/src/assets/images/home/strength3.png);
}
.st-inbox:nth-child(5) .st-innerbox {
  background-image: url(/src/assets/images/home/strength4.png);
}
.st-inbox:nth-child(6) .st-innerbox {
  background-image: url(/src/assets/images/home/strength5.png);
}
.st-inbox:nth-child(7) .st-innerbox {
  background-image: url(/src/assets/images/home/strength6.png);
}
.st-inbox:nth-child(8) .st-innerbox {
  background-image: url(/src/assets/images/home/strength7.png);
}

/* smaller than 1024 */
@media screen and (max-width: 1024px) {
  .innerSbox {
    height: 90%;
    max-height: none;
    padding: 0 10px;
  }

  .textbox {
    max-width: 100%;
  }
}
/* smaller than 768px */
@media screen and (max-width: 768px) {
  .st-inbox {
    width: 50%;
    padding-top: 50%;
  }
}
