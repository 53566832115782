/* ------------------------------------------header */
.mainTab {
  position: relative;
}
.mainTab::before,
.mainTab::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  border-top: 1.2px solid #fff;
  transform: scaleX(0);
  transition: transform 0.2s;
}
.mainTab::before {
  top: calc(50% - 2rem);
}
.mainTab::after {
  bottom: calc(50% - 2rem);
}
.mainTabLine::before,
.mainTabLine::after {
  transform: scaleX(1);
}
.submenuBack {
  position: absolute;
  top: 10.5rem;
  left: 0;
  width: 100%;
  height: 300px;
  background-color: #fff;
  transform: scaleY(0);
  transform-origin: top;
  transition: transform 0.2s;
}
#GNB .submenu {
  top: 8rem;
  height: 300px;
  transform: scaleY(0);
  transform-origin: top;
  transition: transform 0.2s;
}
.open .submenuBack {
  transform: scaleY(1);
  transition: transform 0.2s;
}
.open #GNB .submenu {
  height: 300px;
  transform: scaleY(1);
  transition: transform 0.2s;
  border-right: 1px solid #bbbbbb;
}
#MNB .submenu {
  height: auto;
  width: 100%;
  text-align: center;
  margin-top: 0;
}

.open #GNB .subtab {
  border-top: 3px solid #e2470e;
}
.hover\:font-bold:hover {
  font-weight: bold;
}
.cutton-open {
  display: block;
  width: 100%;
  min-height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.setlang > a {
  position: relative;
}
.setlang > a:hover {
  opacity: 0.5;
}
.setlang > .langSelected::after {
  content: "";
  display: block;
  width: 24px;
  height: 2px;
  background-color: #fff;
  position: absolute;
  left: calc(50% - 12px);
  bottom: -2px;
}

@media (min-width: 1024px) {
  .cutton-open {
    display: none;
  }
}
.nav-hide {
  top: 0;
  right: 0;
  transform: translateX(100%);
  transition: transform 0.3s;
}
.nav-open {
  top: 0;
  left: none;
  right: 0;
  transform: translateX(0);
  transition: transform 0.3s;
}
.header-main {
  background-color: rgba(48, 47, 73, 0.8);
}
/* footer */
.h-300 {
  height: 30rem;
}
.afterbar::after {
  content: "|";
  margin: 0 1rem;
}
@media screen and (max-width: 640px) {
  .afterbar::after {
    content: "";
    margin: 0;
  }
  .h-300 {
    height: auto;
  }
}
/* subheader */
#SNB {
  background-color: rgba(19, 21, 41, 0.9);
}
#SNB .smm,
#SNB .sms {
  border-top: 3px solid #e2470e;
}
.sub_back {
  height: 51.2rem;
}

.linebar::after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  width: 1px;
  height: 24px;
  top: calc(50% - 12px);
  background-color: rgba(255, 255, 255, 0.5);
}
@media screen and (max-width: 640px) {
  .linebar::after {
    width: 0;
  }
  .border-b-button {
    border-bottom: 1px solid var(--main2);
  }
  .border-b-text::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0.8rem;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 10rem);
    height: 2px;
    background-color: var(--main2);
    animation: normal;
  }
}
