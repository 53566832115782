.mtt {
  position: relative;
  display: inline-block;
}
.mtt::before {
  content: "";
  display: block;
  position: absolute;
  left: -75px;
  top: -15px;
  width: 104px;
  height: 16px;
  background: url(/src/assets/icon/subtitle-1.png) no-repeat center center /
    cover;
}
.mtt::after {
  content: "";
  display: block;
  position: absolute;
  left: 3px;
  bottom: -12px;
  width: 224px;
  height: 48px;
  background: url(/src/assets/icon/subtitle-2.png) no-repeat center center /
    cover;
}
