.recruit-work-grid {
  display: grid;
  grid-template-columns: 1fr 3fr;
  column-gap: 16px;
  row-gap: 6rem;
}
.recruit-work-round-left {
  border-radius: 50px 0 0 50px;
}
.recruit-work-round-right {
  border-radius: 0 50px 50px 0;
}
/* benefit */
.en-benefit-height,
.benefit-height {
  height: 37.5rem;
}
.recruit-benefit-grid {
  display: grid;
  grid-template-columns: 1fr 2.8fr;
}
/* announce */
.announce-grid {
  display: grid;
  grid-template-columns: 1fr 3fr;
  column-gap: 16px;
}
.announce-rounded-left {
  border-radius: 30px 0 0 30px;
}
.announce-rounded-right {
  border-radius: 0 30px 30px 0;
}
.announce-height {
  height: 28rem;
}

/* talent */
.confade1 {
  width: calc(7.5% + 0.6px);
  left: calc(27.5% - 0.5px);
  top: calc(50% - 0.15px);
  height: 0.3px;
}
.connect1 {
  width: 100%;
  height: 100%;
  background-color: #e5e5e5;
}
.confade2 {
  width: calc(7.2%);
  left: calc(65.5% - 0.5px);
  top: calc(50% - 0.15px);
  height: 0.3px;
}
.connect2 {
  width: 100%;
  height: 100%;
  background-color: #e5e5e5;
}

@media screen and (max-width: 640px) {
  .recruit-work-grid {
    column-gap: 8px;
  }
  .recruit-work-round-left {
    border-radius: 30px 0 0 30px;
  }
  .recruit-work-round-right {
    border-radius: 0 30px 30px 0;
  }
  .announce-height {
    height: 35rem;
  }
  .en-benefit-height {
    height: 65rem;
  }
}
