html {
  scroll-behavior: smooth;
}
.MouseOverImage {
  position: absolute;
  width: 60rem;
  height: 34rem;
  background: no-repeat center center / cover;
}
.text-45xl {
  font-size: 2.6rem;
}
.listborder li {
  border: 1px solid #e6e7e9;
  border-right: 0;
}
.listborder li:nth-child(3n) {
  border-right: 1px solid #e6e7e9;
}
.listborder li:nth-child(1),
.listborder li:nth-child(2),
.listborder li:nth-child(3) {
  border-bottom: 0;
}
.listborder li:last-child {
  border-right: 1px solid #e6e7e9;
}
.listborder li.selectList {
  border-top: 2px solid #e2470e;
}

/* smaller than 1024 */

@media screen and (max-width: 1024px) {
  .text-45xl {
    font-size: 2.1rem;
  }
  .facil-m-border li:nth-child(2n) {
    border-right: 1px solid #e6e7e9;
  }
  .facil-m-border li:nth-child(3) {
    border-right: 0;
  }
}
/* smaller than 	768px */

@media screen and (max-width: 768px) {
  .text-45xl {
    font-size: 2rem;
  }
  .prd-m-border li:nth-child(3) {
    border-right: 0;
  }
  .prd-m-border li:nth-child(2n) {
    border-right: 1px solid #e6e7e9;
  }
  .prd-m-border li:nth-child(1),
  .prd-m-border li:nth-child(2),
  .prd-m-border li:nth-child(3),
  .prd-m-border li:nth-child(4) {
    border-bottom: 0;
  }
}
/* smaller than 	640px */
@media screen and (max-width: 640px) {
  .text-45xl {
    font-size: 2rem;
  }
  .facilS .swiper-pagination-bullet {
    width: 30px;
  }
}
